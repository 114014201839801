import { useState, useEffect } from "react";

export enum EScreenSize {
   MOBILE = "mobile",
   TABLET = "tablet",
   DESKTOP = "desktop",
}

const useResponsive = () => {
   // Default to null during SSR to avoid incorrect assumptions
   const [screenSize, setScreenSize] = useState<EScreenSize | null>(null);

   useEffect(() => {
      const handleResize = () => {
         const width = window.innerWidth;

         if (width < 768) {
            setScreenSize(EScreenSize.MOBILE);
         } else if (width >= 768 && width < 1024) {
            setScreenSize(EScreenSize.TABLET);
         } else {
            setScreenSize(EScreenSize.DESKTOP);
         }
      };

      // Set initial screen size
      handleResize();

      // Listen for window resize events
      window.addEventListener("resize", handleResize);

      // Cleanup when component unmounts
      return () => window.removeEventListener("resize", handleResize);
   }, []);

   // During SSR, make a best guess based on user agent if available
   // Otherwise default to DESKTOP for SSR
   return screenSize || EScreenSize.DESKTOP;
};

export default useResponsive;
